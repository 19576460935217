/*
 * Finder | Directory & Listings React / Next.js Bootstrap Template
 * Copyright 2022 Createx Studio
 * Theme styles
 */

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
//  @import 'utilities';

// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';
// Override default variables before the import
// //$body-bg: #cccccc;
// $carre-purple: #2c3188;
// // $carre-blue: #3b7cff;
// $carre-yellow: #e0c323;
// $carre-red: #f36f6f;
// $carre-green: #79c10c;
// $carre-gray: #f5f8fc;
// $carre-gray-bg: #707070;
// $carre-white: #ded9d9;
// $carre-blue: #3899da;
// $carre-black: #0f0f0f;

// //custom color to be mapped
// $accent: #da6d25;

// // this is used to map colors. change accordingly.
// $theme-colors: (
//   // add any additional color below
//         carre-purple: $carre-purple,
//         carre-blue: $carre-blue,
//         carre-yellow: $carre-yellow,
//         carre-red: $carre-red,
//         carre-green: $carre-green,
//         carre-gray: $carre-gray,
//         carre-gray-bg: $carre-gray-bg,
//         carre-progres-light: #82b1ff,
//         carre-white: #ffffff,
//         carre-black: $carre-black,
//   // now bg-accent,btn-accent,etc.. can be called
// );
// // @import './scss/components';
// // @import './scss/variables';
// // @import './scss/utilities';

// // @import './scss/reboot';
// // @import 'components';


// // Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";
// .btn-outline-carre-blue:hover, .btn-outline-carre-blue:focus, .btn-outline-carre-blue:active, .btn-outline-carre-blue.active {
//   color: white;
// }
// Override default variables before the import
//$body-bg: #cccccc;
$carre-purple: #2c3188;
// $carre-blue: #3b7cff;
$carre-yellow: #e0c323;
$carre-red: #f36f6f;
$carre-green: #79c10c;
$carre-gray: #ffffff !default;
$carre-gray-bg: #dcdada !default;
$carre-white: #ded9d9;
$carre-blue: #3899da;
$carre-black: #0f0f0f;

//custom color to be mapped
$accent: #da6d25;
.custom-hover:hover {
  cursor: pointer;

}

// // this is used to map colors. change accordingly.
// $theme-colors: (
//   // add any additional color below
//         carre-purple: $carre-purple,
//         carre-blue: $carre-blue,
//         carre-yellow: $carre-yellow,
//         carre-red: $carre-red,
//         carre-green: $carre-green,
//         carre-gray: $carre-gray,
//         carre-gray-bg: $carre-gray-bg,
//         carre-progres-light: #82b1ff,
//         carre-white: #ffffff,
//         carre-black: $carre-black,
//   // now bg-accent,btn-accent,etc.. can be called
// );
// @import './scss/components';
// @import './scss/variables';
// @import './scss/utilities';  

// @import './scss/reboot';
// @import 'components';


// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";
.btn-outline-carre-blue:hover, .btn-outline-carre-blue:focus, .btn-outline-carre-blue:active, .btn-outline-carre-blue.active {
  color: white;
}