.macbook-pro {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.macbook-pro .div {
    background-color: #ffffff;
    border: 1px none;
    height: 4108px;
    position: relative;
    width: 1512px;
}

.macbook-pro .overlap {
    background-color: #ffffff;
    border: 2px solid;
    border-color: #3899da;
    border-radius: 50px;
    height: 409px;
    left: 89px;
    position: absolute;
    top: 2928px;
    width: 1303px;
}

.macbook-pro .layer {
    height: 334px;
    left: 64px;
    position: absolute;
    top: 33px;
    width: 508px;
}

.macbook-pro .lorem-ipsum-has-been {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 669px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 110px;
    width: 600px;
}

.macbook-pro .text-wrapper {
    color: #3899da;
    font-family: "Work Sans-SemiBold", Helvetica;
    font-size: 48px;
    font-weight: 600;
    left: 669px;
    letter-spacing: 0.96px;
    line-height: normal;
    position: absolute;
    top: 37px;
    white-space: nowrap;
    width: 569px;
}

.macbook-pro .button {
    background-color: #3899da;
    border: 1px solid;
    border-radius: 10px;
    height: 44px;
    left: 665px;
    position: absolute;
    top: 235px;
    width: 160px;
}

.macbook-pro .text-wrapper-2 {
    color: #ffffff;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 18px;
    left: 37px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 12px;
    white-space: nowrap;
    width: 86px;
}

.macbook-pro .overlap-group {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 182px;
    left: 96px;
    position: absolute;
    top: 1258px;
    width: 639px;
}

.macbook-pro .image {
    height: 150px;
    left: 16px;
    position: absolute;
    top: 16px;
    width: 150px;
}

.macbook-pro .group {
    height: 101px;
    left: 178px;
    position: absolute;
    top: 48px;
    width: 451px;
}

.macbook-pro .p {
    color: #212529;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 40px;
    white-space: nowrap;
    width: 445px;
}

.macbook-pro .text-wrapper-3 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 68px;
    width: 423px;
}

.macbook-pro .text-wrapper-4 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 114px;
}

.macbook-pro .overlap-2 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 182px;
    left: 777px;
    position: absolute;
    top: 1258px;
    width: 639px;
}

.macbook-pro .group-2 {
    height: 80px;
    left: 178px;
    position: absolute;
    top: 48px;
    width: 451px;
}

.macbook-pro .text-wrapper-5 {
    color: #212529;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 40px;
    white-space: nowrap;
    width: 445px;
}

.macbook-pro .text-wrapper-6 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 68px;
    white-space: nowrap;
    width: 445px;
}

.macbook-pro .text-wrapper-7 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 212px;
}

.macbook-pro .img {
    height: 150px;
    left: 16px;
    position: absolute;
    top: 16px;
    width: 150px;
}

.macbook-pro .nav {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #3899da;
    border-radius: 20px;
    height: 82px;
    left: 96px;
    position: absolute;
    top: 25px;
    width: 1320px;
}

.macbook-pro .div-container {
    height: 60px;
    left: 11px;
    position: relative;
    top: 11px;
    width: 1298px;
}

.macbook-pro .link-SVG {
    height: 40px;
    left: 12px;
    position: absolute;
    top: 10px;
    width: 116px;
}

.macbook-pro .div-basic-navbar-nav {
    align-items: center;
    display: flex;
    gap: 214.35px;
    left: 144px;
    padding: 8px 0.02px 8px 214.36px;
    position: absolute;
    top: 0;
    width: fit-content;
}

.macbook-pro .div-mx-auto {
    height: 40px;
    min-width: 442.27px;
    position: relative;
}

.macbook-pro .link-home {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 24px;
    left: 8px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 7px;
    white-space: nowrap;
}

.macbook-pro .link-marketplace {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 24px;
    left: 94px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 7px;
    white-space: nowrap;
}

.macbook-pro .link-about-us {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 24px;
    left: 230px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 7px;
    white-space: nowrap;
}

.macbook-pro .link-help-center {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 24px;
    left: 342px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 7px;
    white-space: nowrap;
}

.macbook-pro .div-mt {
    align-items: flex-start;
    display: flex;
    gap: 11px;
    position: relative;
    width: fit-content;
}

.macbook-pro .group-wrapper {
    border: 1px solid;
    border-color: #3899da;
    border-radius: 10px;
    height: 44px;
    min-width: 130px;
    position: relative;
}

.macbook-pro .group-3 {
    height: 18px;
    left: 27px;
    position: relative;
    top: 13px;
    width: 78px;
}

.macbook-pro .SVG {
    height: 17px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 17px;
}

.macbook-pro .text-wrapper-8 {
    color: #3899da;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 18px;
    left: 33px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: 43px;
}

.macbook-pro .div-wrapper {
    background-color: #3899da;
    border: 1px solid;
    border-radius: 10px;
    height: 44px;
    min-width: 130px;
    position: relative;
}

.macbook-pro .text-wrapper-9 {
    color: #ffffff;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 18px;
    left: 22px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 12px;
    white-space: nowrap;
    width: 86px;
}

.macbook-pro .heading-how-real {
    color: #0f0f0f;
    font-family: "Work Sans-SemiBold", Helvetica;
    font-size: 48px;
    font-weight: 600;
    left: 96px;
    letter-spacing: 0.96px;
    line-height: normal;
    position: absolute;
    top: 252px;
    white-space: nowrap;
    width: 322px;
}

.macbook-pro .heading-how-real-2 {
    color: #0f0f0f;
    font-family: "Work Sans-SemiBold", Helvetica;
    font-size: 48px;
    font-weight: 600;
    left: 96px;
    letter-spacing: 0.96px;
    line-height: normal;
    position: absolute;
    top: 697px;
    white-space: nowrap;
    width: 322px;
}

.macbook-pro .heading-how-real-3 {
    color: #0f0f0f;
    font-family: "Work Sans-SemiBold", Helvetica;
    font-size: 48px;
    font-weight: 600;
    left: 96px;
    letter-spacing: 0.96px;
    line-height: normal;
    position: absolute;
    top: 1169px;
    white-space: nowrap;
    width: 626px;
}

.macbook-pro .at-carre-we-ve-wrapper {
    background-color: #f6f6f6;
    border-radius: 20px;
    height: 325px;
    left: 96px;
    position: absolute;
    top: 317px;
    width: 772px;
}

.macbook-pro .at-carre-we-ve {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 23px;
    width: 724px;
}

.macbook-pro .overlap-3 {
    background-color: #d9d9d9;
    background-image: url(https://picsum.photos/800/600);

    background-size: 100% 100%;
    border-radius: 75px;
    height: 150px;
    left: 96px;
    position: absolute;
    top: 1512px;
    width: 150px;
}

.macbook-pro .overlap-4 {
    background-color: #d9d9d9;
    background-image: url(https://picsum.photos/800/600);

    background-size: 100% 100%;
    border-radius: 75px;
    height: 150px;
    left: 96px;
    position: absolute;
    top: 1686px;
    width: 150px;
}

.macbook-pro .ellipse {
    background-color: #d9d9d9;
    border-radius: 75px;
    height: 150px;
    left: 96px;
    position: absolute;
    top: 1860px;
    width: 150px;
}

.macbook-pro .overlap-5 {
    background-color: #d9d9d9;
    background-image: url(https://picsum.photos/800/600);

    background-size: 100% 100%;
    border-radius: 75px;
    height: 150px;
    left: 540px;
    position: absolute;
    top: 1512px;
    width: 150px;
}

.macbook-pro .overlap-6 {
    height: 150px;
    left: 540px;
    position: absolute;
    top: 1686px;
    width: 151px;
}

.macbook-pro .ellipse-2 {
    background-color: #d9d9d9;
    border-radius: 75px;
    height: 150px;
    left: 0;
    position: absolute;
    top: 0;
    width: 150px;
}

.macbook-pro .image-2 {
    height: 150px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 150px;
}

.macbook-pro .image-wrapper {
    background-color: #d9d9d9;
    border-radius: 75px;
    height: 150px;
    left: 540px;
    position: absolute;
    top: 1860px;
    width: 150px;
}

.macbook-pro .image-3 {
    height: 150px;
    left: 0;
    position: absolute;
    top: 0;
    width: 149px;
}

.macbook-pro .overlap-7 {
    height: 150px;
    left: 985px;
    position: absolute;
    top: 1512px;
    width: 151px;
}

.macbook-pro .ellipse-3 {
    background-color: #d9d9d9;
    border-radius: 75px;
    height: 150px;
    left: 0;
    position: absolute;
    top: 0;
    width: 150px;
}

.macbook-pro .image-4 {
    height: 150px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 150px;
}

.macbook-pro .overlap-8 {
    background-color: #d9d9d9;
    background-image: url(https://picsum.photos/800/600);

    background-size: 100% 100%;
    border-radius: 75px;
    height: 150px;
    left: 985px;
    position: absolute;
    top: 1686px;
    width: 150px;
}

.macbook-pro .ellipse-4 {
    background-color: #d9d9d9;
    border-radius: 75px;
    height: 150px;
    left: 985px;
    position: absolute;
    top: 1860px;
    width: 150px;
}

.macbook-pro .group-4 {
    height: 51px;
    left: 1159px;
    position: absolute;
    top: 1562px;
    width: 272px;
}

.macbook-pro .text-wrapper-10 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 268px;
}

.macbook-pro .text-wrapper-11 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-5 {
    height: 75px;
    left: 1159px;
    position: absolute;
    top: 1724px;
    width: 272px;
}

.macbook-pro .text-wrapper-12 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 268px;
}

.macbook-pro .text-wrapper-13 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    width: 249px;
}

.macbook-pro .group-6 {
    height: 59px;
    left: 1159px;
    position: absolute;
    top: 1906px;
    width: 272px;
}

.macbook-pro .text-wrapper-14 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 268px;
}

.macbook-pro .text-wrapper-15 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-7 {
    height: 51px;
    left: 714px;
    position: absolute;
    top: 1562px;
    width: 253px;
}

.macbook-pro .text-wrapper-16 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .text-wrapper-17 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-8 {
    height: 60px;
    left: 714px;
    position: absolute;
    top: 1732px;
    width: 253px;
}

.macbook-pro .text-wrapper-18 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 244px;
}

.macbook-pro .text-wrapper-19 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-9 {
    height: 59px;
    left: 714px;
    position: absolute;
    top: 1906px;
    width: 253px;
}

.macbook-pro .text-wrapper-20 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 244px;
}

.macbook-pro .text-wrapper-21 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-10 {
    height: 51px;
    left: 270px;
    position: absolute;
    top: 1562px;
    width: 253px;
}

.macbook-pro .text-wrapper-22 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .text-wrapper-23 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-11 {
    height: 60px;
    left: 270px;
    position: absolute;
    top: 1732px;
    width: 253px;
}

.macbook-pro .text-wrapper-24 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .text-wrapper-25 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .group-12 {
    height: 55px;
    left: 270px;
    position: absolute;
    top: 1908px;
    width: 253px;
}

.macbook-pro .text-wrapper-26 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 19px;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .text-wrapper-27 {
    color: #212529;
    font-family: "Noto Sans-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 41px;
    white-space: nowrap;
    width: 249px;
}

.macbook-pro .div-w {
    background-color: #f5f4f8;
    height: 655px;
    left: 0;
    position: absolute;
    top: 3453px;
    width: 1512px;
}

.macbook-pro .div-d-sm-inline {
    height: 621px;
    left: 150px;
    position: relative;
    width: 1136px;
}

.macbook-pro .div-hd-bd {
    height: 581px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 160px;
}

.macbook-pro .SVG-2 {
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 116px;
}

.macbook-pro .div-my {
    align-items: center;
    display: flex;
    gap: 4px;
    left: 0;
    padding: 0px 49px 0px 0px;
    position: absolute;
    top: 72px;
    width: fit-content;
}

.macbook-pro .email {
    height: 12px;
    min-width: 18px;
    position: relative;
}

.macbook-pro .text-wrapper-28 {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .div-my-2 {
    height: 50px;
    left: -10px;
    position: absolute;
    top: 114px;
    width: 170px;
}

.macbook-pro .div-my-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8.79px;
    left: 260px;
    padding: 0px 0px 424.38px;
    position: absolute;
    top: 40px;
    width: fit-content;
}

.macbook-pro .heading-quick {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0px 9.69px 0px 0px;
    position: relative;
    width: fit-content;
}

.macbook-pro .item-link-calculate {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .item-link-top-offers {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .item-link-top-cities {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .div-my-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8.79px;
    left: 528px;
    padding: 0px 0px 400.38px;
    position: absolute;
    top: 40px;
    width: fit-content;
}

.macbook-pro .heading-about {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 23px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .list-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0px 4.2px 0px 0px;
    position: relative;
    width: fit-content;
}

.macbook-pro .item-link-about-us {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .item-link-help {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .item-link-contacts {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .item-link-news {
    color: #212529;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.macbook-pro .div-hd-cfdb {
    height: 581px;
    left: 736px;
    position: absolute;
    top: 40px;
    width: 400px;
}

.macbook-pro .heading-featured {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 29px;
    left: 0;
    letter-spacing: 0;
    line-height: 28.8px;
    position: absolute;
    top: -2px;
    white-space: nowrap;
}

.macbook-pro .div-d-flex {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 0px 61.84px 0px 0px;
    position: absolute;
    top: 37px;
    width: fit-content;
}

.macbook-pro .footerhero {
    background-image: url(https://picsum.photos/800/600);

    background-position: 50% 50%;
    background-size: cover;
    height: 110px;
    min-width: 110px;
    position: relative;
}

.macbook-pro .div-hd-bea {
    height: 158.19px;
    min-width: 338.16px;
    position: relative;
}

.macbook-pro .home-improvement {
    color: #2c3188;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 0;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .text-wrapper-29 {
    color: #212529;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 20px;
    left: 0;
    letter-spacing: 0;
    line-height: 19.2px;
    position: absolute;
    top: 32px;
    white-space: nowrap;
}

.macbook-pro .p-text-muted {
    align-items: flex-start;
    display: flex;
    left: 0;
    padding: 0px 24px 0px 0px;
    position: absolute;
    top: 61px;
    width: fit-content;
}

.macbook-pro .mi-justo-varius {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.macbook-pro .div-d-flex-2 {
    align-items: flex-start;
    display: flex;
    gap: 15.99px;
    left: 0;
    padding: 0px 180.01px 0px 0px;
    position: absolute;
    top: 140px;
    width: fit-content;
}

.macbook-pro .p-mb {
    height: 18px;
    min-width: 53.02px;
    position: relative;
}

.macbook-pro .calendar {
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.macbook-pro .text-wrapper-30 {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 20px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .p-mb-2 {
    height: 18px;
    min-width: 89.14px;
    position: relative;
}

.macbook-pro .message {
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.macbook-pro .text-wrapper-31 {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 20px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .separator {
    border-color: #212529;
    border-top-style: solid;
    border-top-width: 1px;
    height: 1px;
    left: 0;
    opacity: 0.25;
    position: absolute;
    top: 329px;
    width: 400px;
}

.macbook-pro .div-d-flex-3 {
    align-items: flex-start;
    display: flex;
    gap: 15px;
    left: 0;
    padding: 0px 3.83px 0px 0px;
    position: absolute;
    top: 354px;
    width: fit-content;
}

.macbook-pro .footerhero-2 {
    background-image: url(https://picsum.photos/800/600);

    background-position: 50% 50%;
    background-size: cover;
    height: 110px;
    min-width: 110px;
    position: relative;
}

.macbook-pro .div-hd-cabc {
    height: 227.19px;
    min-width: 271.17px;
    position: relative;
}

.macbook-pro .tips-advice {
    color: #2c3188;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 0;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .text-wrapper-32 {
    color: #212529;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 20px;
    left: 0;
    letter-spacing: 0;
    line-height: 19.2px;
    position: absolute;
    top: 32px;
    white-space: nowrap;
}

.macbook-pro .volutpat-orci-vitae-wrapper {
    align-items: flex-start;
    display: flex;
    left: 0;
    padding: 0px 21px 0px 0px;
    position: absolute;
    top: 61px;
    width: fit-content;
}

.macbook-pro .volutpat-orci-vitae {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.macbook-pro .div-d-flex-4 {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    left: 0;
    padding: 0px 97.86px 0px 0px;
    position: absolute;
    top: 161px;
    width: fit-content;
}

.macbook-pro .p-mb-3 {
    height: 18px;
    min-width: 59.67px;
    position: relative;
}

.macbook-pro .calendar-2 {
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.macbook-pro .text-wrapper-33 {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 20px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .p-mb-4 {
    height: 18px;
    min-width: 97.64px;
    position: relative;
}

.macbook-pro .message-2 {
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.macbook-pro .text-wrapper-34 {
    color: #6c757d;
    font-family: "Noto Sans-Light", Helvetica;
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    left: 20px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
}

.macbook-pro .group-13 {
    height: 260px;
    left: 96px;
    position: absolute;
    top: 809px;
    width: 736px;
}

.macbook-pro .text-wrapper-35 {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 0;
    width: 688px;
}

.macbook-pro .text-wrapper-36 {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 71px;
    width: 688px;
}

.macbook-pro .text-wrapper-37 {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 142px;
    width: 688px;
}

.macbook-pro .text-wrapper-38 {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 213px;
    width: 688px;
}

.macbook-pro .ellipse-5 {
    background-color: #3899da;
    border-radius: 12px;
    height: 24px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 24px;
}

.macbook-pro .ellipse-6 {
    background-color: #3899da;
    border-radius: 12px;
    height: 24px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 71px;
    width: 24px;
}

.macbook-pro .ellipse-7 {
    background-color: #3899da;
    border-radius: 12px;
    height: 24px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 142px;
    width: 24px;
}

.macbook-pro .ellipse-8 {
    background-color: #3899da;
    border-radius: 12px;
    height: 24px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 213px;
    width: 24px;
}

.macbook-pro .rocket {
    align-items: center;
    border: 2px solid;
    border-color: #3899da;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    left: 1012px;
    overflow: hidden;
    padding: 50px;
    position: absolute;
    top: 788px;
    transform: rotate(-173.21deg);
    width: fit-content;
}

.macbook-pro .rocket-2 {
    height: 213.35px;
    margin-left: -10.68px;
    margin-right: -10.68px;
    margin-top: -10.67px;
    min-width: 213.35px;
    position: relative;
    transform: rotate(173.21deg);
}

.macbook-pro .illustration {
    height: 355px;
    left: 901px;
    position: absolute;
    top: 287px;
    width: 515px;
}

.macbook-pro .h-1 {
    color: #3899da;
    font-family: "Work Sans-Bold", Helvetica;
    font-size: 52px;
    font-weight: 700;
    left: 96px;
    letter-spacing: 0;
    line-height: 48px;
    position: absolute;
    text-align: center;
    top: 162px;
    white-space: nowrap;
}

.macbook-pro .heading-how-real-4 {
    color: #0f0f0f;
    font-family: "Work Sans-SemiBold", Helvetica;
    font-size: 48px;
    font-weight: 600;
    left: 580px;
    letter-spacing: 0.96px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 2081px;
    white-space: nowrap;
    width: 322px;
}

.macbook-pro .overlap-group-wrapper {
    height: 148px;
    left: 65px;
    position: absolute;
    top: 2178px;
    width: 667px;
}

.macbook-pro .overlap-group-2 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 148px;
    position: relative;
    width: 663px;
}

.macbook-pro .text-wrapper-39 {
    color: #212529;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 65px;
    width: 615px;
}

.macbook-pro .text-wrapper-40 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .overlap-wrapper {
    height: 148px;
    left: 752px;
    position: absolute;
    top: 2178px;
    width: 668px;
}

.macbook-pro .overlap-9 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 148px;
    position: relative;
    width: 664px;
}

.macbook-pro .text-wrapper-41 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 65px;
    width: 616px;
}

.macbook-pro .text-wrapper-42 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .group-14 {
    height: 150px;
    left: 752px;
    position: absolute;
    top: 2350px;
    width: 668px;
}

.macbook-pro .overlap-10 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 664px;
}

.macbook-pro .text-wrapper-43 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 616px;
}

.macbook-pro .text-wrapper-44 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .group-15 {
    height: 150px;
    left: 65px;
    position: absolute;
    top: 2524px;
    width: 667px;
}

.macbook-pro .overlap-11 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 663px;
}

.macbook-pro .text-wrapper-45 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 615px;
}

.macbook-pro .text-wrapper-46 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .group-16 {
    height: 150px;
    left: 752px;
    position: absolute;
    top: 2524px;
    width: 668px;
}

.macbook-pro .overlap-12 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 664px;
}

.macbook-pro .text-wrapper-47 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 616px;
}

.macbook-pro .text-wrapper-48 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .group-17 {
    height: 150px;
    left: 65px;
    position: absolute;
    top: 2698px;
    width: 667px;
}

.macbook-pro .overlap-13 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 663px;
}

.macbook-pro .text-wrapper-49 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 615px;
}

.macbook-pro .text-wrapper-50 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}

.macbook-pro .group-18 {
    height: 150px;
    left: 752px;
    position: absolute;
    top: 2698px;
    width: 668px;
}

.macbook-pro .overlap-14 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 664px;
}

.macbook-pro .text-wrapper-51 {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 616px;
}

.macbook-pro .text-wrapper-52 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 291px;
}

.macbook-pro .group-19 {
    height: 150px;
    left: 65px;
    position: absolute;
    top: 2350px;
    width: 667px;
}

.macbook-pro .overlap-15 {
    background-color: #f6f6f6;
    border-radius: 16px;
    height: 150px;
    position: relative;
    width: 663px;
}

.macbook-pro .we-uphold-the {
    color: #000000;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 66px;
    width: 615px;
}

.macbook-pro .text-wrapper-53 {
    color: #3899da;
    font-family: "Noto Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 24px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 24px;
    white-space: nowrap;
    width: 220px;
}
