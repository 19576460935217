/* fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:300,400');


.wrapper {
	/* width: 90%;
	font-family: 'Open Sans', sans-serif;
	padding: 50px 0px; */
    align-self: center;
    justify-content: center;
}

.app-wrapper {
	background-color: #fff;
	height: auto;
	max-width: 2000px;
	margin: 0 auto;
	border-radius: 8px;
	display: flex;

	/* -webkit-box-shadow: 0px 8px 24px 4px rgba(255,173,40,1);
	-moz-box-shadow: 0px 8px 24px 4px rgba(255,173,40,1);
	box-shadow: 0px 8px 24px 4px #adadad; */
}

.wallet {
	width: 360px;
	background-color: #ffffff;
	height: 100%;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
	padding: 50px;
	box-shadow: 0px 8px 10px 4px #adadad;
	
}

.wallet-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  
  .deposit-button,
  .withdraw-button {
    background-color: #3899da; /* Green background */
    color: white; /* White text */
    border: none; /* No border */
    padding: 5px 10px; /* Padding */
    text-align: center; /* Center text */
    text-decoration: none; /* No underline */
    display: inline-block;
    font-size: 20px;
    margin: 10px; /* Add some margin between the buttons */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
  }
  
  .deposit-button:hover,
  .withdraw-button:hover {
    background-color: #3899da; /* Darker green on hover */
  }
.wallet h2 {
	display:inline-block;
}

.transactions-wrapper {
	width: 1720px;
	padding: 20px;
}
.total-balance-container {
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  }
  
  .total-balance-left {
	flex: 1;
	text-align: left;
  }
  
  .total-balance-right {
	flex: 1;
margin-top: 10px;
	text-align: right;
  }
  
  .total-balance {
	font-weight: 600;
	font-size: 22px;
	color: #444750;
  }

.transactions {
	margin-top: 60px;
	border-top: 1px solid #e5e5e5;
	padding-top: 50px;
	height: 550px;
	overflow: scroll;
}

.transactions.show {
	animation: fade-in .3s 1;
}

.transactions::-webkit-scrollbar { 
    display: none; 
}

.transaction-item {
	margin-bottom: 45px;
}

.transaction-item {
	padding-left: 10px;
	position: relative;
	display: flex;
}
.transaction-item::before {
	position: absolute;
	content: '';
	border: 2px solid #e1e1e1;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	left: 0px;
	top: 10px;
	box-sizing: border-box;
	box-sizing: border-box;
	vertical-align: middle;
	color: #666666;
}

.transaction-item.credit::before {
	content: '\002B';
	font-size: 25px;
	line-height: 19px;
	padding: 0px 4px 0px;
}

.transaction-item.credit .transaction-item_amount .amount,
.transaction-item.credit .transaction-item_amount span{
	color: #66cc33;
}

.transaction-item.debit::before {
	content: '\2212';
	font-size: 20px;
	line-height: 21px;
  padding: 0px 5px;
}	

.transaction-item.debit .transaction-item_amount .amount,
.transaction-item.debit .transaction-item_amount span{
	color: #8393ca;
}

.transaction-item span.details {
	font-size: 14px;
	line-height: 14px;
	color: #999;
}

.transaction-item_details {
	width: 270px;
}

.transaction-item_amount {
	width: 110px;
	text-align: right;
}
.transaction-item_amount span {
	font-weight: 600;
	font-size: 18px;
	line-height: 45px;
}

.transaction-item_amount .amount {
	font-weight: 600;
	font-size: 18px;
	line-height: 45px;
	position: relative;
	margin: 0px;
	display: inline-block;
	text-indent: -15px;
}

/* Hide + and - */
.transaction-item_amount .amount::first-letter {
	color: transparent !important;
}


.credit-card {
	  padding: 15px;
    background-color: #fff;
    margin-bottom: 45px;
		border-radius: 6px;
    border: 2px solid #e1e1e1;
		font-family: 'Roboto', sans-serif;
		cursor: pointer;
		transition: .1s ease-in-out;
        min-width: 100%
}
.credit-card:hover {
	/* transform: scale(1.07); */
	background: #f2f2f2;
	
}

.credit-card.active {
	border-color: #afafaf;
	border-width: 1px;
    border-radius: 6px;
}

.card-image {
	display: inline-block;
	height: 20px;
	width: 308px;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 3px;
	margin-bottom: 10px;
}



.credit-card_expiration {
	color: #b3b3b3;
}

.credit-card_number {
	color: #666666;
 
}

.credit-card {
    max-width: 300px; /* Set the maximum width */
  }
  
  .credit-card_number, .credit-card_expiration {
    word-wrap: break-word; /* Allow text to wrap */
  }
.card-list {
	margin-top: 20px;
}

.card-list .card-image {
	cursor: pointer;
	margin-right: 15px;
	transition: .1s;
}

.card-list .card-image:hover {
	transform: scale(1.1);
}



/* animations */
@keyframes fade-in {
	0% {
		opacity: 0;
	}
  100% {
    opacity: 1;
  }
}

/* media queries */
@media(max-width:1010px) {
	.wrapper {
		border-radius: 8px;
	}    
	.wallet {
		width: 100%;
		border-top-right-radius: inherit;
		padding-bottom: 25px;
	}
	.cards {
		margin-top: 25px;
	}
	.app-wrapper {
		-webkit-flex-direction: column;
    flex-direction: column;
		width: 100%;
		border-top-right-radius: inherit;
		height: initial;
	}
	.credit-card {
		width: 100%;
    max-width: 100%;
    display: inline-block;
    margin-right: 25px;
		margin-bottom: 25px;
    text-align: left;
	}
	.credit-card:nth-of-type(2) {
		margin-right: 0px;
	}
	.transactions {
		height: initial;
	}
	.transactions-wrapper {
		width: 100%;
	}
	.transaction-item_amount {
		width: calc(100% - 270px);
	}

}

.button-container {
    display: flex;
    justify-content: space-between;
  }

  .custom-button {
    width: calc(50% - 5px);
  }

  @media screen and (max-width: 768px) {
    .button-container {
      flex-direction: column;
    }

    .custom-button {
      width: 100%;
      margin-bottom: 10px; /* Add space between buttons on smaller screens */
    }
  }

/* Button Styles */
.custom-button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	border: 2px solid #3899da; /* Add a blue border */
	border-radius: 6px;
    width: calc(50% - 5px);
	cursor: pointer;
	background-color: white; /* White background */
	color: #3899da; /* Blue text color */
	transition: background-color var(--tran-03), color var(--tran-03);
  }
  
  .custom-button:hover {
	background-color: #3899da; /* Change to blue background on hover */
	color: white; /* Change to white text color on hover */
  }
  
  /* Add more styles as needed */
  
@media(max-width:760px) {
	h3 {
		line-height: 24px;
	}
	.cards {
		text-align: center;
	}
	.credit-card {
		width: calc(100% - 25px);
    max-width: 260px;
	}
	.credit-card:nth-of-type(2) {
		margin-right: 25px;
	}	
	.credit-card:last-of-type {
		margin-bottom: 0px;
	}
	.total-balance-left,
	.total-balance-right {
	  flex: auto;
	  text-align: center;
	  margin-top: -5px;
	}
  
	.total-balance {
	  display: block;
	  margin: 0 auto; /* Center the element horizontally */
	  margin-top: 10px; /* Add some space between the elements */
	}
	.transaction-item_amount {
		width: 100px;
	}
		.custom-button{
		width: 100%;
		margin-top: 5px;
	}
}

@media(max-width: 390px) {
	.wallet {
		padding: 50px 25px;
	}
    
	.transactions-wrapper {
		padding: 50px 25px;
	}
	
}
.header-container {
    display: flex;
    align-items: center;
  }

  .header-container h3 {
	margin-top: 15px;
    margin-right: 10px; /* Add space between the text and the image */
  }

  @media screen and (max-width: 768px) {
    .header-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .header-container h3 {
      margin-right: 0; /* Remove right margin */
    }
  }

  .blockchain_info:hover {
	background-color: #d8d8d8;
	color: #000000;
	
  }