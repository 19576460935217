.square {
    background-color: #f5f5f5;
    padding: 1rem;
}

.border-end {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

@media (min-width: 992px) {
    .myDiv {
        border-top: 1px solid black;
        border-left: 1px;
        border-bottom: 1px solid black;
        border-radius: 10px;
    }
}

/* ON SMALLER SCREENS: Apply border to Top */
@media (max-width: 991px) {
    .myDiv {
        border-top: 1px solid black;
        border-radius: 10px;
    }
}

.border-start {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
}

.text-carre-black {
    color: #1b1b1b;
}

.text-carre-blue {
    color: #5cb3fd;
}

.text-carre-purple {
    color: #6a4ceb;
}

.outline-carre-red {
    color: #d9534f;
    border-color: #d9534f;
}

.small {
    font-size: 0.75rem;
}

h6 {
    font-size: 0.9rem;
}
