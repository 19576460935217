.custom-modal-container {
    background-image: url('https://images.unsplash.com/photo-1564013799919-ab600027ffc6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    filter: brightness(95%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1px;
}

.custom-close-button {
    cursor: pointer;
    font-size: 1.5rem;
    color: #333333;
    position: absolute;
    top: 10px;
    right: 10px;
}

.custom-form-container {
    /* Media query for smaller screens */
    @media (max-width: 768px) {
        min-width: 320px;
        margin:4% 4%;
    }

    @media (max-width: 1200px) {
        min-width: 60%;
    }        margin: 9% 20%;

}
