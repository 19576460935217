//
// Interactive Map
// Based on https://react-leaflet.js.org/
// --------------------------------------------------


// Zoom controls

.leaflet-touch {
  .leaflet-control-layers,
  .leaflet-bar {
    border: 0 !important;
    box-shadow: $box-shadow !important;
  }
  .leaflet-bar a {
    transition: $nav-link-transition !important;
    color: $nav-link-color !important;
    font-weight: normal !important;
    line-height: 26px !important;
    &:first-child {
      border-bottom-color: $border-color !important;
      border-top-left-radius: $border-radius-sm !important;
      border-top-right-radius: $border-radius-sm !important;
    }
    &:last-child {
      border-bottom-left-radius: $border-radius-sm !important;
      border-bottom-right-radius: $border-radius-sm !important;
    }
    &:hover {
      background-color: $white !important;
      color: $nav-link-hover-color !important;
    }
    &.leaflet-disabled {
      background-color: $white !important;
      color: rgba($nav-link-disabled-color, .7) !important;
    }
  }
}


// Custom marker: Dot

.custom-marker-dot {
  width: .875rem !important;
  height: .875rem !important;
  margin: {
    top: -.75rem !important;
    left: -.375rem !important;
  }
  padding-left: .875rem !important;
  transition: box-shadow .2s ease-in-out !important;
  border-radius: 50% !important;
  box-sizing: border-box !important;
  background: $primary !important;

  &:hover {
    box-shadow: 0 0 0 .625rem rgba($primary, .25) !important;
  }
}


// Popup

.leaflet-popup-content-wrapper {
  min-width: 17.5rem !important;
  padding: 0 !important;
  overflow: hidden !important;
  .leaflet-popup-content {
    width: 100% !important;
    margin: 0 !important;
    color: $body-color !important;
  }
}
.leaflet-container {
  font-family: $font-family-sans-serif !important;
  a.leaflet-popup-close-button {
    top: .5rem !important;
    right: .5rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0 !important;
    background-color: $white !important;
    border-radius: 50% !important;
    color: rgba($gray-800, .7) !important;
    font-size: .875rem !important;
    line-height: 1.5rem !important;
    text-align: center !important;
    box-shadow: $box-shadow-sm !important;
    &:hover { color: $gray-800 !important; }
  }
}
.leaflet-popup:focus,
.leaflet-marker-icon:focus { outline: none !important; }
.leaflet-popup-tip-container {
  backface-visibility: hidden !important;
}
@-moz-document url-prefix() {
  .leaflet-container a.leaflet-popup-close-button {
    line-height: 1.375rem !important;
  }
}
.leaflet-container a {
  color: $link-color !important;
  &:hover { color: $link-color !important; }
  &.nav-link {
    color: $nav-link-color !important;
    &:hover { color: $nav-link-hover-color !important; }
  }
  &.nav-link-light {
    color: $nav-link-light-color !important;
    &:hover { color: $nav-link-light-hover-color !important; }
  }
}
.leaflet-control-attribution a {
  color: $nav-link-color !important;
}


// Hide / show map popup

.map-popup {
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
  background-color: $white;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  .btn-icon {
    position: absolute;
    top: $spacer;
    right: $spacer;
    z-index: 999;
  }
}

.map-popup,
.map-popup .leaflet-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

@include media-breakpoint-down(lg) {
  .map-popup {
    position: fixed !important;
    z-index: $zindex-fixed + 1;
  }
}
