.range-picker-container {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    width: 100%;
}


.range-picker-input {
    width: 100%;
    max-width: 700px;
    font-size: 16px;
}

.custom-input {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
    color: #333;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*width: 100%; !* Change to 100% to ensure full-width *!*/
}

.custom-input:hover {
    border-color: rgba(44, 138, 197, 0.76);
}

.react-datepicker {
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-family: Arial, sans-serif;
}

.react-datepicker__month-container {
    width: auto;
}

.react-datepicker__header {
    background-color: white;
    border-bottom: none;
    padding-top: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 40px;
    line-height: 40px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
    background-color: rgba(44, 138, 197, 0.76);
    color: white;
    border-radius: 50%;
}

.react-datepicker__day--in-range {
    background-color: #ffe1e8;
    color: rgba(44, 138, 197, 0.76);
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: black;
}

.react-datepicker__current-month {
    font-size: 16px;
    font-weight: bold;
}

.react-datepicker__navigation-icon {
    top: 15px;
}

.react-datepicker__day--disabled {
    color: #ddd;
}

.react-datepicker__day:hover {
    background-color: rgba(44, 138, 197, 0.76);
    color: white;
}

.react-datepicker__close-icon::after {
    background-color: rgba(44, 138, 197, 0.76);
    border-radius: 50%;
    color: white;
}

/* Responsiveness */
@media (max-width: 768px) {
    .custom-input {
        width: 100%;
        font-size: 14px;
        padding: 8px 15px;
    }

    .range-picker-input {
        font-size: 14px;
    }
}
