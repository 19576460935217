//
// Modal
// --------------------------------------------------


.signin-modal,
.signup-modal { 
  .modal-dialog {
    max-width: 950px;
    margin: {
      top: .25rem;
      top: .25rem;
    }
    padding: .5rem;
  } 
}
