/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    /* Colors */
    --sidebar-color: #fff;
    --primary-color: #3899da;
    --primary-color-light: #f6f5ff;
    --toggle-color: #ddd;
    --text-color: #3c4b6d;

    /* Transitions */
    --tran-03: all 0.2s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}

/* Body Styles */
body {
    min-height: 100vh;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

::selection {
    background-color: var(--primary-color);
    color: #fff;
}

/* Dark Mode Styles */
body.dark {
    --body-color: #18191a;
    --sidebar-color: #242526;
    --primary-color: #3b73ac;
    --primary-color-light: #3a3b3c;
    --toggle-color: #fff;
    --text-color: #ccc;
}

/* Sidebar Styles */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    padding: 20px 14px;
    background: var(--sidebar-color);
    transition: var(--tran-05);
    z-index: 1000;

}

.sidebar.close {
    width: 88px;
}

/* Reusable Code Styles */
.sidebar li {
    height: 36px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 4px;


}

.sidebar header .image,
.sidebar .icon {
    min-width: 20px;
    border-radius: 6px;
}

.sidebar .icon {
    min-width: 45px;
    border-radius: 6px;
    height: 100%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: 7px;
}

.sidebar .text,
.sidebar .icon {
    color: var(--text-color);
    transition: var(--tran-03);
}

.sidebar .text {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 1;
}

.sidebar.close .text {
    opacity: 0;
}

/* Header Styles */
.sidebar header {
    position: relative;
}

.sidebar header .image-text {
    display: flex;
    align-items: center;
}

.sidebar header .logo-text {
    display: flex;
    flex-direction: column;
}

header .image-text .name {
    margin-left: 40px;

    font-size: 15px;
    font-weight: 600;
}

header .image-text .profession {
    margin-left: 40px;
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar header .image img {
    width: 40px;
    border-radius: 6px;
}

.sidebar header .toggle {
    position: absolute;
    top: 50%;
    right: -25px;
    /* transform: translateY(-50%) rotate(180deg); */
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
    color: var(--text-color);
}

.sidebar.close .toggle {
    transform: translateY(-50%) rotate(0deg);
}

/* Menu Styles */
.sidebar .menu {
    margin-top: 20px;
}

.sidebar li.search-box {
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
}

.sidebar li.search-box input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
}

.sidebar li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.sidebar li a:hover {
    background-color: var(--primary-color);
    cursor: pointer;
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
    color: var(--sidebar-color);
}

body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
    color: var(--text-color);
}

.sidebar li .active {
    background-color: var(--primary-color);
}

.sidebar li .active .icon,
.sidebar li .active .text {
    color: var(--sidebar-color);
}

body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
    color: var(--text-color);
}

/* Menu Bar Styles */
.sidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
    display: none;
}

/* Mode Styles */
.sidebar .menu-bar .mode {
    border-radius: 6px;
    background-color: var(--primary-color-light);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
    height: 50px;
    width: 60px;
}

.mode .sun-moon i {
    position: absolute;
}

.mode .sun-moon i.sun {
    opacity: 0;
}

body.dark .mode .sun-moon i.sun {
    opacity: 1;
}

body.dark .mode .sun-moon i.moon {
    opacity: 0;
}

/* Toggle Switch Styles */
.menu-bar .bottom-content .toggle-switch {
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}

.toggle-switch i {
    font-size: 16px;
    color: var(--toggle-color);
}

body.dark .toggle-switch i {
    color: var(--text-color);
}

/* Navigation Styles */
.menu-bar .menu {
    margin-bottom: 30px;
}

.menu-bar .menu li.nav-link {
    padding: 0;
    width: 100%;
}

.menu-bar .menu li.nav-link a.nav-link-custom {
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 100%;
    height: 100%;
    color: var(--text-color);
    text-decoration: none;
    transition: var(--tran-03);
}

.menu-bar .menu li.nav-link a.nav-link-custom .icon {
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-bar .menu li.nav-link a.nav-link-custom:hover {
    background-color: var(--primary-color-light);
    color: var(--primary-color);
}

body.dark .menu-bar .menu li.nav-link a.nav-link-custom:hover {
    background-color: var(--primary-color-light);
    color: var(--text-color);
}

/* Override Bootstrap Styles */
/* For example, to change the background color of the navbar */
.navbar {
    background-color: var(--your-custom-color);
}

/* ... (override other Bootstrap styles as needed) */
