.sidebar {
    position: fixed;
    top: 72px;
    left: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    width: 275px;
    transition: all 0.1s; &.closed {
      width: 80px;
      height: 100%; /* Set the desired height for your sidebar */
      overflow-y: auto;
    }
    .sidebar-content {
      padding: 10px;
    }
    .sidebar-item {
      display: flex;
      align-items: center;
      color: #333;
      text-decoration: none;
      padding: 15px 10px;
      padding-left: 12px;
    z-index: 100;
     

    
      justify-content: center;
      /*border-radius: 20px;*/
      /*margin-bottom: 10px;*/
      &:hover {
        background-color: #d8d8d9;
   
      }
      .text-color{
        color: #d0d0d2;
        &:hover{
            color:#fff;
        }
      }
      
      &.active {
        background-color: #2c3188;
        color: #fff;
      }
      .sidebar-icon {
        padding-top: 4px;
        margin-bottom: 22px;
        margin-right: 10px;
          width: 25px;
          height: 15px;
        
      }
      .sidebar-text {
        flex-grow: 1;
      }
    }
  }
  .sidebar header .image .circular-div {
    width: 45px; /* Adjust the width to your preference */
    height: 45px; /* Adjust the height to your preference */
    border-radius: 50%; /* Makes the div circular */
    background-color: var(--primary-color); /* Background color of the circular div */
    position: absolute; /* Position it over the image */
    margin-left: 35px;
    display: flex; /* Use flexbox to center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    color: white; /* Set the text color to white */
    font-weight: bold; /* You can also make the text bold if needed */
    font-size: 16px; /* Adjust the font size as desired */
  }
  
  
  .main-content {
    margin-left: 275px;
    margin-top: -10px;
    transition: all 0.2s;
    &.closed {
      margin-left: 80px;
    }
    .container-fluid {
      padding:20px;
    }
  }
  
  .navbar {
    z-index: 998;
    .nav-link {
      color: #333;
      &:hover {
        color: #2c3188;
      }
    }
    .navbar-toggler {
      border-color: transparent;
    }
    .navbar-brand {
      font-weight: bold;
    }
    .ml-auto {
      display: flex;
      align-items: center;
    }
    .mr-auto {
      margin-right: 20px;
    }
  }
  
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    width: 100%; /* Ensure content takes full width */
  }