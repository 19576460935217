body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .unauthorized-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .unauthorized-content {
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  h1 {
    color: #e74c3c;
  }
  
  p {
    color: #333;
    margin: 10px 0;
  }
  