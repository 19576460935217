//
// File uploader
// based on https://github.com/pqina/filepond
// --------------------------------------------------


.file-uploader {
  margin-bottom: 0 !important;
  border: $border-width dashed $gray-400 !important;
  border-radius: $border-radius-lg !important;
  font-family: $font-family-sans-serif !important;
  cursor: pointer !important;
}
.filepond--root .filepond--drop-label {
  min-height: 10rem !important;
  > label { cursor: pointer !important; }
}

.filepond--panel,
.filepond--panel-root {
  background-color: transparent !important;
}

.filepond--image-preview {
  background-color: $gray-900 !important;
}

.filepond--credits { display: none !important; }

@include media-breakpoint-up(sm) {
  .file-uploader-grid {
    .filepond--item {
      width: calc(50% - .5em) !important;
    }
  }
}

[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: $danger !important;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: $success !important;
}
